'use strict'
const _ = require('lodash')
const urlUtils = require('./urlUtils')

function resolve(apps) {
    const componentsFromSpecMap = _(apps)
        .map(app => buildWidget(app.widgets))
        .compact()
        .flatten()
        .values()
        .value()

    if (typeof window !== 'undefined') {
        const overrides = urlUtils.getParameterByName('nativeComponentsOverrides')
        if (overrides) {
            try {
                const nativeComponentsOverrides = JSON.parse(overrides)
                const appsOverrides = _.map(nativeComponentsOverrides, component => ({
                    widgetId: component.widgetId, 
                    type: `native.components.${component.componentName}`
                }))
                return mergeByProperty(componentsFromSpecMap, appsOverrides, 'widgetId')
        
            } catch (error) {
                //do nothing as this is for local overrides
            }
        }
    }   
    return componentsFromSpecMap
}

function mergeByProperty(componentsFromSpecMap, appsOverrides, prop) {
    appsOverrides.forEach(override => {
        const element = _.find(componentsFromSpecMap, specMapComp => specMapComp[prop] === override[prop])
        if (element) {
            _.assignIn(element, override)
        } else {
            componentsFromSpecMap.push(override)
        }
    })
    return componentsFromSpecMap
}

function buildWidget(widgets = {}) {
    const widgetsWeSupport = _(widgets)
        .map(widget => {
            if (_.get(widget, 'componentFields.componentName') && _.get(widget, 'componentFields.componentUrl')) {
                return {
                    widgetId: widget.widgetId,
                    type: `native.components.${widget.componentFields.componentName}`
                }
            }
            return undefined
        })
        .compact()
        .values()
        .value()

    return _.isEmpty(widgetsWeSupport) ? undefined : widgetsWeSupport
}

module.exports = resolve
