'use strict'

const _ = require('lodash')
const requestsUtil = require('./requestsUtil')

function getDataFixedPage(fetchFunc, request) {
    const requestToSend = {
        url: request.url || request.urls[0],
        dataType: 'json',
        type: 'GET',
        cache: 'default',
        name: request.name
    }

    requestToSend.success = (resData, status, xhr) => {
        const headersStr = _.invoke(xhr, 'getAllResponseHeaders') || ''
        const headersObj = requestsUtil.parseResponseHeaders(headersStr)
        _.invoke(request, 'callback', resData, resData, requestToSend.url, headersObj)
    }

    requestToSend.error = (xhrRequest, errName) => {
        _.invoke(request, 'onUrlRequestFailure', requestToSend.url, _.get(xhrRequest, 'status'), {
            name: errName,
            status: _.get(xhrRequest, 'status'),
            responseText: _.get(xhrRequest, 'responseText')
        })
    }

    _.invoke(request, 'onBeforeFetch')

    fetchFunc(requestToSend)
}


module.exports = {
    getDataFixedPage
}
